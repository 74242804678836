import styles from "./Model.module.scss"
import Gallery from "../Gallery/Gallery"
import Colors from "../Colors/Colors"
import {useContext, useState} from "react"
import Button from "../Button/Button"
import {ModalContext} from "../../contexts/ModalContext"

export default function Model({data}) {
	const {handleModal} = useContext(ModalContext)
	const [currentColor, setCurrentColor] = useState(data?.colors[0].name)
	const [type, setType] = useState("exterior")
	const getExteriorImages = () => {
		return Array.from({length: 5}).map((_, i) => `/images/models/${data.imgPath}/exterior/${currentColor}/${i + 1}.jpg`)
	}

	const getInteriorImages = () => {
		return Array.from({length: data.interiorPhotosCount || 0}).map((_, i) => `/images/models/${data.imgPath}/interior/${i + 1}.jpg`)
	}
	const handleClick = (type) => () => setType(type)

	return (
		<div className={styles.root}>
			<div className={styles.title}>{data?.name}</div>

			<div className={styles.wrapper}>
				<div className={styles.controls}>
					<div className={styles.radio} onClick={handleClick("exterior")}>
						<svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'>
							<circle cx='16.5' cy='16' r='13' stroke='currentColor' strokeWidth='2' />
							{type === "exterior" && <circle cx='16.5' cy='16' r='6' fill='currentColor' />}
						</svg>
						<div>Экстерьер</div>
					</div>
					<div className={styles.radio} onClick={handleClick("interior")}>
						<svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'>
							<circle cx='16.5' cy='16' r='13' stroke='currentColor' strokeWidth='2' />
							{type === "interior" && <circle cx='16.5' cy='16' r='6' fill='currentColor' />}
						</svg>
						<div>Интерьер</div>
					</div>
				</div>
				<div className={styles.description}>{data.description[type]}</div>
				<Gallery images={type === "exterior" ? getExteriorImages() : getInteriorImages()} currentColor={currentColor} type={type} />
				{type === "exterior" && <Colors colors={data.colors} currentColor={currentColor} setCurrentColor={setCurrentColor} />}
				<div className={styles.buttonWrap}>
					<Button type={"primary"} onClick={() => handleModal(`Забронируй новый ${data?.name}`)}>
						Забронировать
					</Button>
				</div>
			</div>
		</div>
	)
}
