import "rc-slider/assets/index.css"

import Slider from "rc-slider"
import {useContext, useEffect, useState} from "react"

import Button from "../Button/Button"
import styles from "./CreditCalculator.module.scss"
import {ModalContext} from "../../contexts/ModalContext"
import {decimalFormatter} from "../../utils/formatters"
import Container from "../Container"
import {models} from "../../configs/configModels"
import CreditFeatures from "../CreditFeatures/CreditFeatures"

export function CreditCalculator() {
	const {handleModal} = useContext(ModalContext)
	const [modelId, setModelId] = useState(0)

	const minSum = 30000
	const maxSum = 3000000

	const minFirstSum = 30000

	const minDuration = 6
	const maxDuration = 84

	const [sum, setSum] = useState(models[modelId].price)
	const [firstSum, setFirstSum] = useState(models[modelId].price ? Math.round(models[modelId].price * 0.7) : 0)
	const [duration, setDuration] = useState(60)
	const percentage = 0.01
	const [payM, setPayM] = useState(0)

	function credit(sum = minSum, mouth = 6, stavka = 0.01) {
		if (duration > 5) {
			const s = stavka / 100 / 12
			const v = 1 + s
			const l = +Math.pow(v, -mouth).toFixed(9)
			const a = +(1 - l).toFixed(9)
			const k = +(s / a).toFixed(9)
			setPayM(Math.ceil(sum * k))
		} else {
			setPayM("---")
		}
	}

	useEffect(() => {
		credit(sum - firstSum, duration, percentage)
	}, [sum, duration, percentage, firstSum])

	useEffect(() => {
		setSum(models[modelId].price)
		setFirstSum(Math.round(models[modelId].price * 0.7))
		setDuration(60)
	}, [modelId])

	function onFirstSumChange(sum, firstSum) {
		if (firstSum <= maxSum - minFirstSum) {
			setFirstSum(firstSum)
			if (firstSum >= sum - minFirstSum) {
				setSum(+firstSum + minFirstSum)
			}
		} else {
			setSum(maxSum)
			setFirstSum(maxSum - minFirstSum)
		}
	}

	function onDurationChange(duration) {
		if (+duration > maxDuration) {
			setDuration(maxDuration)
		} else if (duration === "") {
			setDuration("")
		} else {
			setDuration(+duration)
		}
	}

	const sliderStyles = {
		style: {
			transform: "translateY(-7px)",
			marginLeft: 8,
			width: "calc(100% - 16px)",
			zIndex: 904,
		},
		handleStyle: {
			marginTop: -8,
			border: "solid 2px #FA8C37",
			borderRadius: "50%",
			backgroundColor: "#FA8C37",
			width: 20,
			height: 20,
			opacity: 1,
		},
		trackStyle: {
			backgroundColor: "#FA8C37",
		},
	}

	return (
		<Container>
			<section className={`calculator ${styles.root}`} id={"calculator"}>
				<div className={styles.img}>
					<img loading={"lazy"} src='/images/credit-calc.jpg' alt='' width={893} height={793} />
				</div>
				<div className={styles.wrapper}>
					<div className={styles.title}>Рассчитай кредит OMODA</div>
					<CreditFeatures />
					<form onSubmit={(e) => e.preventDefault()}>
						<div className={styles.inputWrap}>
							<div className={styles.selectModel} onChange={(e) => setModelId(+e.target.value)}>
								{models.map((model, idx) => (
									<div className={`${styles.model} ${idx === modelId ? styles.active : ""}`} key={idx} onClick={() => setModelId(idx)}>
										<img src={`/images/${model.imgPath}.jpg`} alt='' />
										{model.name}
									</div>
								))}
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.inputWrap}>
								<span>Первый взнос, руб.*</span>
								<input
									type='text'
									inputMode={"numeric"}
									name={"firstSum"}
									value={decimalFormatter(firstSum)}
									onChange={(e) => onFirstSumChange(sum, e.target.value.replace(/^0\s|\D/g, ""))}
								/>
								<Slider
									{...sliderStyles}
									step={1000}
									value={+firstSum}
									onChange={(value) => onFirstSumChange(sum, value)}
									min={0}
									max={maxSum - minFirstSum}
								/>
							</div>
							<div className={styles.inputWrap}>
								<span>Срок кредитования, мес.</span>
								<input
									type='text'
									name={"duration"}
									value={duration}
									inputMode={"numeric"}
									onChange={(e) => onDurationChange(e.target.value.replace(/^0\s|\D/g, ""))}
								/>
								<Slider {...sliderStyles} value={duration} onChange={(value) => setDuration(() => value)} min={minDuration} max={maxDuration} />
							</div>
						</div>

						<div className={styles.resultWrapper}>
							<div className={styles.result}>
								<span className={styles.resultLabel}>Ежемесячный платёж:</span>
								<span className={styles.resultNumber}>от {decimalFormatter(payM)} ₽/мес</span>
							</div>
							<Button
								message={`Рассчитать платеж`}
								title={"Рассчитать платеж"}
								onClick={() => handleModal("Рассчитать платеж", "Рассчитать платеж", models[modelId].name)}
								fullwidth
								type={"primary"}
							>
								Рассчитать
							</Button>
						</div>
						<div className={styles.disclaimer}>
							<p>*Рекомендованный первый взнос ~ 10-20% от стоимости авто</p>
							<p>**Ставка зависит от наличия страховых продуктов</p>
						</div>
					</form>
				</div>
			</section>
		</Container>
	)
}
