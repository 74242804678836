import styles from "./Footer.module.scss"
import Logo from "../Logo/Logo"
import Container from "../Container"

export default function Footer() {
	return (
		<footer className={styles.footer}>
			<Container className='container'>
				<div className={styles.logo}>
					<Logo color={"white"} />
				</div>

				<div className={styles.copyright}>&copy; {new Date().getFullYear()} Автосалон Максимум Север. Официальный дилер Omoda в Санкт-Петербурге.</div>
				<div>
					<a className={styles.privacy} href='/privacy-policy' target='_blank'>
						Правовая информация
					</a>
					<div className={styles.copyright}>
						<noindex>
							<p>
								<b>Зарегистрированное название компании:</b>
								<br />
								Общество с ограниченной ответственностью «Максимум Кредит»
								<br />
								<b>Адрес:</b>
								<br />
								улица Руставели, 53
								<br />
								Санкт-Петербург, 195299
								<br />
								Россия
							</p>
						</noindex>
					</div>
				</div>
			</Container>
		</footer>
	)
}
