import Banner from "../Banner/Banner"
import ModelsList from "../ModelsList/ModelsList"
import CallToActionForm from "../CallToActionForm/CallToActionForm"
import {Contacts} from "../Contacts/Contacts"
import {CreditCalculator} from "../CreditCalculator/CreditCalculator"
import Advantages from "../Advantages/Advantages"

export default function MainPage() {
	return (
		<main>
			<Banner />
			<ModelsList />
			<CallToActionForm title={"TRADE-IN выше рынка"} description={"Обменяйте свой автомобиль на Omoda с выгодой"} name={"trade-in"} />
			<CreditCalculator />
			<CallToActionForm title={"Запись на тест-драйв"} description={"Прочувствуйте комфорт и динамику OMODA"} name={"test-drive"} />
			<Advantages />
			<Contacts />
		</main>
	)
}
