import {useState} from "react"

import styles from "./Gallery.module.scss"
import {useKeenSlider} from "keen-slider/react"
import {ThumbnailPlugin} from "../../utils/ThumbnailPlugin"
import Icon from "../Icon"
import Arrow from "../Arrow/Arrow"

export default function Gallery({images = [], currentColor}) {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [loaded, setLoaded] = useState(false)

	const MutationPlugin = (slider) => {
		const observer = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				slider.update(slider.options)
			})
		})
		const config = {childList: true}

		slider.on("created", () => {
			observer.observe(slider.container, config)
		})
		slider.on("destroyed", () => {
			observer.disconnect()
		})
	}

	const mainOptions = {
		mode: "free-snap",
		slides: {
			perView: 1.2,
			spacing: 4,
		},
		breakpoints: {
			"(min-width: 920px)": {
				mode: "snap",
				slides: {
					perView: 1,
					spacing: 8,
				},
			},
		},
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel)
		},
		created() {
			setLoaded(true)
		},
	}

	const thumbnailOptions = {
		vertical: true,
		initial: 0,
		slides: {
			perView: 4,
			spacing: 16,
		},
		breakpoints: {
			"(min-width: 1600px)": {
				slides: {
					perView: 5,
					spacing: 16,
				},
			},
		},
	}
	const [sliderRef, instanceRef] = useKeenSlider(mainOptions, [MutationPlugin])

	const [thumbnailRef] = useKeenSlider(thumbnailOptions, [ThumbnailPlugin(instanceRef), MutationPlugin])

	return (
		<div className={styles.photos}>
			<div className={styles.mainPhoto} id='mainPhoto'>
				<div className={"keen-slider"} ref={sliderRef}>
					{images.map((image, i) => (
						<div className={"keen-slider__slide"} key={image}>
							<img width={1530} height={1020} loading={"lazy"} src={image} alt='img' className={styles.slideImage} id={"carImage_" + (i + 1)} />
						</div>
					))}
				</div>
				{loaded && instanceRef.current && (
					<>
						<Arrow left onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />
						<Arrow
							onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
							disabled={currentSlide === instanceRef.current.track.details?.slides.length - 1}
						/>
					</>
				)}
			</div>
			<div className={styles.otherImages}>
				{loaded && instanceRef.current && (
					<div className={"keen-slider"} ref={thumbnailRef} style={{height: instanceRef?.current?.size < 1330 ? 540 : 680}}>
						{images.map((e, i, arr) => (
							<div
								key={"carousel" + e}
								id={i === 1 ? "center" : undefined}
								onClick={() => instanceRef?.current?.moveToIdx(i)}
								className={`keen-slider__slide ${styles.otherImage}`}
							>
								<img loading={"lazy"} src={e} alt={"img"} width={1530} height={1020} />
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	)
}
