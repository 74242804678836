import {useLocation} from "react-router-dom"
import styles from "./Navigation.module.scss"

export default function Navigation() {
	const {pathname} = useLocation()
	const isPolicyPage = pathname === "/privacy-policy"

	const handleClick = (id) => {
		return (evt) => {
			evt.preventDefault()
			window.scrollTo({
				top: document.getElementById(id).getBoundingClientRect().top + window.scrollY - 118,
				behavior: "smooth",
			})
		}
	}

	return (
		<nav className={styles.root}>
			{!isPolicyPage && (
				<div className={styles.wrapper}>
					<div className={styles.link} onClick={handleClick("trade-in")}>
						Trade-in
					</div>
					<div className={styles.link} onClick={handleClick("test-drive")}>
						Тест-драйв
					</div>
					<div className={styles.link} onClick={handleClick("calculator")}>
						Кредит
					</div>
					<div className={styles.link} onClick={handleClick("contacts")}>
						Контакты
					</div>
				</div>
			)}
		</nav>
	)
}
