import styles from "./Colors.module.scss"
import Icon from "../Icon"

export default function Colors({colors = [], currentColor = "black", setCurrentColor = () => {}}) {
	const handleClick = (color) => () => {
		setCurrentColor(color)
	}

	return (
		<div className={styles.root}>
			{colors.map((color = {}) => (
				<div key={color.name} className={styles.color} style={{backgroundColor: color.hex}} onClick={handleClick(color.name)}>
					{currentColor === color.name && <Icon.ColorCheck />}
				</div>
			))}
		</div>
	)
}
