import "./assets/styles/normalize.css"
import "./assets/styles/globals.css"
import "keen-slider/keen-slider.min.css"

import {lazy, Suspense} from "react"

import {Route, Routes} from "react-router-dom"
import NotificationPermission from "./NotificationPermission"
import {ModalProvider} from "./contexts/ModalContext"
import MainPage from "./components/MainPage/MainPage"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"

const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"))

function App() {
	return (
		<div className='App'>
			<NotificationPermission />
			<Header />
			<ModalProvider>
				<Routes>
					<Route path='/' element={<MainPage />} />
					<Route
						path='privacy-policy'
						element={
							<Suspense
								fallback={
									<div style={{paddingTop: "calc(50vh - 40px)", paddingBottom: "calc(50vh - 40px)"}} className={"container"}>
										Loading...
									</div>
								}
							>
								<PrivacyPolicy />
							</Suspense>
						}
					/>
				</Routes>
			</ModalProvider>
			<Footer />
		</div>
	)
}

export default App
