export const models = [
	{
		name: "Omoda C5",
		imgPath: "c5",
		price: 2359000,
		serviceSlug: "c5",
		interiorPhotosCount: 11,
		description: {
			exterior:
				"Стильная решетка радиатора, 18” двухцветные литые диски, «парящая» крыша, динамичный кузов фастбэк, Т-образные дневные ходовые огни, динамичные сигналы поворота – каждый элемент подчеркивает спортивный современный образ автомобиля Omoda C5.",
			interior:
				"Передние сиденья выполнены по канонам спорткаров — высокий цельный подголовник, развитая боковая поддержка подушек сидений, перфорированная высококачественная эко-кожа.",
		},
		colors: [
			{
				name: "black",
				hex: "#000000",
			},
			{
				name: "blue",
				hex: "#929FB5",
			},
			{
				name: "dark-blue",
				hex: "#1C2C3B",
			},
			{
				name: "green",
				hex: "#8BAA95",
			},
			{
				name: "dark-green",
				hex: "#3C463C",
			},
			{
				name: "red",
				hex: "#CC1717",
			},
			{
				name: "white",
				hex: "#F5F5F4",
			},
			{
				name: "dark-grey",
				hex: "#404853",
			},
		],
	},
	{
		name: "Omoda S5",
		imgPath: "s5",
		price: 2089900,
		serviceSlug: "s5",
		interiorPhotosCount: 3,
		description: {
			exterior:
				"Спортивный внешний вид создаётся множеством дизайнерских элементов. Спереди обращают на себя внимание воздухозаборники в бампере, при взгляде сбоку вы оцените стремительный профиль, а завершают образ два задних патрубка с алюминиевой окантовкой.",
			interior:
				"Спортивные передние сиденья с развитой с боковой поддержкой, кожаная отделка интерьера, многофункциональный руль и зимний пакет опций, включающий в себя: обогрев ветрового и заднего стекол, наружных зеркал, форсунок стеклоомывателя, рулевого колеса и всех сидений.",
		},
		colors: [
			{
				name: "silver",
				hex: "#8A919B",
			},
			{
				name: "blue",
				hex: "#455A85",
			},
			{
				name: "black",
				hex: "#000000",
			},
			{
				name: "red",
				hex: "#CC1717",
			},
			{
				name: "white",
				hex: "#F5F5F4",
			},
			{
				name: "grey",
				hex: "#616872",
			},
		],
	},
	{
		name: "Omoda S5 GT",
		imgPath: "s5gt",
		price: 2539900,
		serviceSlug: "s5-gt",
		interiorPhotosCount: 5,
		description: {
			exterior:
				"Ровно очерченные стрелки дневных ходовых огней и LED фары делают взгляд седана по-настоящему гипнотическим. Вместе с тем они обеспечивают комфорт всем участникам дорожного движения: фары адаптируются к дорожной обстановке и меняют интенсивность освещения. Максимальное покрытие светодиодов дальнего света – 200 метров.  Спортивный задний бампер дополнен сдвоенными хромированными патрубками и широкой декоративной вставкой под карбон.",
			interior:
				"Спортивные передние сиденья из высококачественной искусственной кожи обладают развитой боковой поддержкой и регулируются электроприводом по шести направлениям. Кресла также оснащены вентиляцией и подогревом.\n" +
				"\n" +
				"В OMODA S5 GT комфорт водителя и пассажиров – в приоритете. Тишина в салоне достигается за счет увеличенной толщины стекол 4 мм и улучшенной шумоизоляции кузова.\n" +
				"\n",
		},
		colors: [
			{
				name: "green",
				hex: "#557F86",
			},
			{
				name: "silver",
				hex: "#8A919B",
			},
			{
				name: "blue",
				hex: "#455A85",
			},
			{
				name: "black",
				hex: "#000000",
			},
			{
				name: "red",
				hex: "#CC1717",
			},
			{
				name: "white",
				hex: "#F5F5F4",
			},
			{
				name: "grey",
				hex: "#616872",
			},
		],
	},
]
