import {useEffect, useState} from "react"

const NotificationPermission = () => {
	const [hasWaited, setHasWaited] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setHasWaited(true)
		}, 10000)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	useEffect(() => {
		if (hasWaited) {
			if (!("Notification" in window)) {
				alert("Браузер не поддерживает уведомления")
			} else if (Notification.permission !== "granted" && Notification.permission !== "denied") {
				Notification.requestPermission()
			}
		}
	}, [hasWaited])

	return null
}

export default NotificationPermission
