import styles from "./Header.module.scss"
import Container from "../Container"
import Logo from "../Logo/Logo"
import Navigation from "../Navigation/Navigation"
import Icon from "../Icon"

export default function Header() {
	return (
		<header className={styles.root}>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<Logo color={"#202020"} />
					<a className={styles.phone} href='tel:+78126038368'>
						<Icon.Phone />
						<span>+7 (812) 603-83-68</span>
					</a>
					<Navigation />
				</div>
			</Container>
		</header>
	)
}
