import {useModal} from "../hooks/useModal"
import {createContext} from "react"
import Modal from "../components/Modal"

const ModalContext = createContext(undefined)

const ModalProvider = ({children}) => {
	const {modal, handleModal, modalTitle, modalMessage, model} = useModal()
	return (
		<ModalContext.Provider value={{modal, handleModal, modalTitle, modalMessage, model}}>
			<Modal />
			{children}
		</ModalContext.Provider>
	)
}

export {ModalContext, ModalProvider}
