import styles from "./Arrow.module.scss"
import Icon from "../Icon"

export default function Arrow(props) {
	return (
		<button onClick={props.onClick} disabled={props.disabled} className={`${props.left ? styles.prev : styles.next} ${styles.root}`}>
			<Icon.ArrowSlider />
		</button>
	)
}
