import styles from "./Logo.module.scss"

export default function Logo({color = "white"}) {
	return (
		<a href={"/"} className={styles.root}>
			<svg width='243' height='32' viewBox='0 0 243 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M35.0021 22.4445H34.9014V22.6512L31.3127 25.9483H10.6766L1 15.0207V25.8476L5.98807 30.7959H36.3962L41.4797 25.7442V18.6305L35.0021 12.2377V22.4445ZM110.374 15.2274L110.274 15.124V9.45481L113.664 5.74424H134.202L144.17 15.9483V5.95362L139.285 1.10602L108.479 1L103.794 5.95097V12.4445L110.374 19.4548V15.2274ZM230.809 1.20673L230.709 1.00265H223.632L234.793 30.5892V30.7959H241.973L230.809 1.20673ZM211.766 1.10602L203.091 30.7959H209.672L216.452 8.83727H224.528L221.636 1L211.766 1.10602ZM7.48594 15.2274L7.38522 15.124V9.45481L10.7777 5.74424H31.4138L41.382 15.9483V5.95362L36.3993 1.10602L5.58824 1L1.00303 5.95097V12.4445L7.48329 19.4548L7.48594 15.2274ZM137.79 22.4445H137.888L137.891 12.2377L144.268 18.6305V25.7442L139.285 30.7959H108.776L103.793 25.8476V15.0207L113.563 25.9483H134.201L137.79 22.6512V22.4445ZM73.4832 21.6202L77.374 1H77.3767H88.5428L93.0247 6.05433V31H86.5444V8.83726H81.6597L77.7716 30.7959L75.0788 30.6926L73.4832 21.6202ZM51.7499 6.25841L56.1363 1.10602H68.0022L73.3851 31H66.8042L63.2155 8.94063H58.3308V30.8966H51.7499V6.25841ZM189.531 6.36178L182.852 1H190.032L196.112 5.95097V25.8449L191.328 30.7906H155.635V1H179.762L185.845 5.95097H162.415V25.9483H184.747L189.531 22.5478V6.36178Z'
					fill={color}
				/>
			</svg>
		</a>
	)
}
